import NavBar from '../components/NavBar';
import './ScheduleExecutionTaskListPage.css';
import { useTranslation } from 'react-i18next';
import { Switch } from '@mui/material';
import Button from '../components/Button';
import { areAllTasksFilledInArea } from '../utils/taskUtils';

const ScheduleExecutionAreaListPage = ({ scheduleExecution, setScheduleExecution, selectedArea, setSelectedArea, handleBack }) => {
  const { t } = useTranslation();
  const isCompleted = scheduleExecution.schedule.areasCompleted?.some(area => area.id === selectedArea.id) || false;

  const handleInputChange = (task, value) => {
    const updatedTasks = selectedArea.tasks.map(t =>
      t.id === task.id ? { ...t, value: { ...t.value, [task.value.type]: value } } : t
    );
    const updatedArea = { ...selectedArea, tasks: updatedTasks };
    const updatedSchedule = {
      ...scheduleExecution.schedule,
      areasToDo: scheduleExecution.schedule.areasToDo?.map(area =>
        area.id === selectedArea.id ? updatedArea : area
      ),
      areasCompleted: scheduleExecution.schedule.areasCompleted?.map(area =>
        area.id === selectedArea.id ? updatedArea : area
      )
    };
    const updatedScheduleExecution = { ...scheduleExecution, schedule: updatedSchedule };
    setSelectedArea(updatedArea);
    console.log('updatedScheduleExecution', updatedScheduleExecution);
    setScheduleExecution(updatedScheduleExecution);
  };

  const handleMove = () => {
    if (isCompleted) {
      const updatedSchedule = {
        ...scheduleExecution.schedule,
        areasCompleted: scheduleExecution.schedule.areasCompleted?.filter(area => area.id !== selectedArea.id),
        areasToDo: [...(scheduleExecution.schedule.areasToDo || []), selectedArea]
      };
      const updatedScheduleExecution = { ...scheduleExecution, schedule: updatedSchedule };
      setScheduleExecution(updatedScheduleExecution);
    } else {
      const updatedSchedule = {
        ...scheduleExecution.schedule,
        areasToDo: scheduleExecution.schedule.areasToDo?.filter(area => area.id !== selectedArea.id),
        areasCompleted: [...(scheduleExecution.schedule.areasCompleted || []), selectedArea]
      };
      const updatedScheduleExecution = { ...scheduleExecution, schedule: updatedSchedule };
      console.log('updatedScheduleExecution', updatedScheduleExecution);
      setScheduleExecution(updatedScheduleExecution);
    }
    handleBack();
  };

  return (
    <div className="schedule-execution-taskList-page">
      <NavBar title={selectedArea?.name} onBackClick={handleBack} />
      {selectedArea?.tasks.map(task => (
        <div key={task.id} className="task-item">
          <span>{task.name}</span>
          {task.value?.type === 'text' && (
            <input
              type="text"
              value={task.value.text || ''}
              onChange={e => handleInputChange(task, e.target.value)}
            />
          )}
          {task.value?.type === 'date' && (
            <input
              type="date"
              value={task.value.date ? new Date(task.value.date * 1000).toISOString().split('T')[0] : ''}
              onChange={e => handleInputChange(task, Math.floor(new Date(e.target.value).getTime() / 1000))}
            />
          )}
          {task.value?.type === 'boolean' && (
            <Switch
              className="task-switch"
              checked={task.value.boolean || false}
              onChange={e => handleInputChange(task, e.target.checked)}
              color="primary"
            />
          )}
          {task.value?.type === 'number' && (
            <input
              type="number"
              value={task.value.number || ''}
              onChange={e => handleInputChange(task, parseFloat(e.target.value))}
            />
          )}
        </div>
      ))}
        <Button 
          id="move-button" 
          variant="primary" 
          onClick={handleMove}
          disabled={!areAllTasksFilledInArea(selectedArea)}
          >
          {isCompleted ? t("scheduleExecution.taskList.moveToToDo") : t("scheduleExecution.taskList.moveToCompleted")}
        </Button>
    </div>
  );

};

export default ScheduleExecutionAreaListPage;
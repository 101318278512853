// src/utils/taskUtils.js

export const areAllTasksFilledInArea = (area) => {
  return (area.tasks || []).every(task => {
    if (task.value?.type === 'text') {
      return task.value.text?.trim() !== '';
    }
    if (task.value?.type === 'date') {
      return task.value.date !== null && task.value.date !== undefined && task.value.date !== '';
    }
    if (task.value?.type === 'number') {
      return task.value.number !== null && task.value.number !== undefined && task.value.number !== '';
    }
    if (task.value?.type === 'boolean') {
      return task.value.boolean !== null && task.value.boolean !== undefined;
    }
    return true;
  });
};
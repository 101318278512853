import React, { createContext, useState, useEffect } from 'react';
import { callGetUserCompanies } from '../functions';
import { auth } from '../firebase'; // Adjust the import path as needed

export const CompanyContext = createContext();

export const CompanyProvider = ({ children }) => {
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(() => {
    const savedCompany = localStorage.getItem('selectedCompany');
    return savedCompany ? JSON.parse(savedCompany) : null;
  });

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const userCompanies = await callGetUserCompanies(user.uid);
          setCompanies(userCompanies);

          const savedCompany = localStorage.getItem('selectedCompany');
          const parsedSavedCompany = savedCompany ? JSON.parse(savedCompany) : null;

          if (parsedSavedCompany && userCompanies.some(company => company.id === parsedSavedCompany.id)) {
            setSelectedCompany(parsedSavedCompany);
          } else if (userCompanies.length > 0) {
            setSelectedCompany(userCompanies[0]);
          } else {
            setSelectedCompany(null);
          }
        } catch (error) {
          console.error('Error fetching companies:', error);
        }
      } else {
        setCompanies([]);
        setSelectedCompany(null);
      }
    });
    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (selectedCompany) {
      localStorage.setItem('selectedCompany', JSON.stringify(selectedCompany));
    } else {
      localStorage.removeItem('selectedCompany');
    }
  }, [selectedCompany]);

  const changeSelectedCompany = (company) => {
    setSelectedCompany(company);
  };

  return (
    <CompanyContext.Provider value={{ companies, selectedCompany, changeSelectedCompany }}>
      {children}
    </CompanyContext.Provider>
  );
};
// src/navigation/TabNavigator.js
import React from "react";
import { Route, Routes, NavLink, useLocation } from "react-router-dom";
import { PiReceiptThin, PiSmileyWinkThin, PiClipboardTextLight, PiUserLight } from "react-icons/pi";
import { useTranslation } from 'react-i18next';
import TicketsScreen from "../screens/TicketsScreen";
import MyDayScreen from "../screens/MyDayScreen";
import ReportsScreen from "../screens/ReportsScreen";
import ProfileScreen from "../screens/ProfileScreen";
import './TabNavigator.css'; // Import the CSS for styling

const TabNavigator = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const hideTabBar = location.pathname === "/schedule-execution";

  return (
    <div className="app-container">
      <div className={`content ${hideTabBar ? 'full-screen' : ''}`}>
        <Routes>
          <Route path="/tickets" element={<TicketsScreen />} exact />
          <Route path="/" element={<MyDayScreen />} />
          <Route path="/myDay" element={<MyDayScreen />} />
          <Route path="/reports" element={<ReportsScreen />} />
          <Route path="/profile" element={<ProfileScreen />} />
        </Routes>
      </div>

      {!hideTabBar && (
        <nav className="tab-bar">
          <ul className="tab-list">
            <li className="tab-item">
              <NavLink to="/tickets" exact activeClassName="active">
                <PiReceiptThin size={24} />
                <span>{t("tickets.title")}</span>
              </NavLink>
            </li>
            <li className="tab-item">
              <NavLink to="/myday" activeClassName="active">
                <PiSmileyWinkThin size={24} />
                <span>{t("myDay.title")}</span>
              </NavLink>
            </li>
            <li className="tab-item">
              <NavLink to="/reports" activeClassName="active">
                <PiClipboardTextLight size={24} />
                <span>{t("reports.title")}</span>
              </NavLink>
            </li>
            <li className="tab-item">
              <NavLink to="/profile" activeClassName="active">
                <PiUserLight size={24} />
                <span>{t("profile.title")}</span>
              </NavLink>
            </li>
          </ul>
        </nav>
      )}
    </div>
  );
};

export default TabNavigator;
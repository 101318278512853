// src/components/CompanyModal.js
import React, { useState } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { PiXLight } from "react-icons/pi";
import "./CompanyModal.css"
import { useTranslation } from 'react-i18next';

Modal.setAppElement('#root');

const CompanyModal = ({ isOpen, onRequestClose, companies, onSelectCompany }) => {
  const { t } = useTranslation();
  const [selectedCompany, setSelectedCompany] = useState(null);

  const handleSelectCompany = (company) => {
    setSelectedCompany(company);
    onSelectCompany(company);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={t("companyModal.selectCompany")}
      className="modal"
      overlayClassName="overlay"
    >
      <div className="modal-header">
        <h4>{t("companyModal.selectCompany")}</h4>
        <button className="close-button" onClick={onRequestClose}>
          <PiXLight size={20} />
        </button>
      </div>
      <ul>
        {companies.map((company) => (
          <li 
          key={company.id} 
          onClick={() => handleSelectCompany(company)}
          className={selectedCompany === company ? 'selected' : ''}>
            {company.name}
          </li>
        ))}
      </ul>
    </Modal>
  );
};

CompanyModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  companies: PropTypes.array.isRequired,
  onSelectCompany: PropTypes.func.isRequired,
};

export default CompanyModal;
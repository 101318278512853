// src/components/ScheduleExecutionStartPage.js
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import NavBar from '../components/NavBar';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { Card, Switch, Typography } from '@mui/material';
import './ScheduleExecutionStartPage.css';
import Button from '../components/Button';
import { getGeoLocation } from '../utils/geoLocationUtils';

const ScheduleExecutionStartPage = ({ scheduleExecution, setScheduleExecution, handleStart, handleBack }) => {
  const { t } = useTranslation();
  const [locationEnabled, setLocationEnabled] = useState(false);
  const [userLocation, setUserLocation] = useState(null);

  const mapContainerStyle = {
    width: '100%',
    height: '400px',
  };

  const center = {
    lat: 34.0522,
    lng: -118.2437,
  };

  const handleToggleChange = () => {
    setLocationEnabled(!locationEnabled);
  };

  useEffect(() => {
    if (locationEnabled) {
      const fetchLocation = async () => {
        try {
          const location = await getGeoLocation();
          setUserLocation(location);
        } catch (error) {
          setUserLocation(null);
          console.error('Error fetching location:', error);
        }
      }
      fetchLocation();
    } else {
      setUserLocation(null);
    }
    const updatedScheduleExecution = { ...scheduleExecution, locationEnabled };
    setScheduleExecution(updatedScheduleExecution);
  }, [locationEnabled]);


  return (
    <div className="schedule-execution-start-page">
      <NavBar 
        title={scheduleExecution.schedule.name} 
        onBackClick={handleBack}/>      
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={userLocation || center}
          zoom={15}
        >
          {userLocation && 
            (
              <Marker
                key="user-location"
                position={userLocation}
              />
            )
          }
        </GoogleMap>
      <Card className="card">
        <div className="switch-container">
          <Typography variant="h6">{t('scheduleExecution.start.shareLocation')}</Typography>
          <Switch
            checked={locationEnabled}
            onChange={handleToggleChange}
            color="primary"
          />
        </div>
        <Typography variant="body2">
        {t('scheduleExecution.start.explanation')}
        </Typography>
      </Card>
      <Button id="start-button" variant="primary" onClick={handleStart}>
        {t('scheduleExecution.start.start')}
      </Button>
    </div>
  );
};

export default ScheduleExecutionStartPage;
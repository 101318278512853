// src/screens/ProfileScreen.js
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../context/UserContext';
import { CompanyContext } from '../context/CompanyContext';
import { useTranslation } from 'react-i18next';
import { signOutUser } from '../firebase';
import NavBar from '../components/NavBar';
import Button from '../components/Button';
import './ProfileScreen.css';
import InfoCard from '../components/InfoCard';
import CompanyModal from '../components/CompanyModal';

const ProfileScreen = () => {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const { selectedCompany, companies, changeSelectedCompany } = useContext(CompanyContext);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSignOut = async () => {
    await signOutUser();
  };

  const handleCompanyChange = () => {
    console.log("Tapi")
    setIsModalOpen(true);
  }

  const handleSelectCompany = (company) => {
    changeSelectedCompany(company);
    setIsModalOpen(false);
  };

  return (
    <div className="profile-container">
      <NavBar title={t("profile.title")} className="navbar" />
      <div className="profile-content">
        {user && (
          <InfoCard
            title={t("profile.user")}
            name={user.name}
            email={user.email}
            address={user.address}
            imageUrl={user.imageUrl}
          />
        )}
        {selectedCompany && (
          <InfoCard
            title={t("profile.company")}
            name={selectedCompany.name}
            email={selectedCompany.email}
            address={selectedCompany.address}
            button={<button className='change-button' onClick={handleCompanyChange}>{t('profile.changeCompany')}</button>}

          />
        )}
      </div>
      <Button
        id="sign-out-button"
        variant="destructive"
        onClick={handleSignOut}
      >
        {t("profile.signOut")}
      </Button>
      <CompanyModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        companies={companies}
        onSelectCompany={handleSelectCompany}
      />
    </div>
  );
};

export default ProfileScreen;

// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TabNavigator from './navigation/TabNavigator';
import LoginScreen from './screens/LoginScreen';
import { useTranslation } from 'react-i18next';
import { UserProvider } from './context/UserContext';
import { CompanyProvider } from './context/CompanyContext';
import { PropertyProvider } from './context/PropertyContext';
import ScheduleExecutionScreen from './screens/ScheduleExecutionScreen';
import TicketDetailsScreen from './screens/TicketDetailsScreen';
import GoogleMapsLoader from './components/GoogleMapsLoader';
import AuthHandler from './AuthHandler'; // Import the new component
import "./App.css"

function App() {
  const { t } = useTranslation();
  return (
<GoogleMapsLoader>
    <UserProvider>
      <CompanyProvider>
        <PropertyProvider>
        <Router>
          <AuthHandler>
              {(user) => (
                user ? (
                  <Routes>
                    <Route path="/scheduleExecution" element={<ScheduleExecutionScreen />} />
                    <Route path="/tickets/:ticketId" element={<TicketDetailsScreen />} />
                    <Route path="*" element={<TabNavigator />} />
                  </Routes>
                ) : (
                  <LoginScreen />
                )
              )}
          </AuthHandler>
        </Router>
        </PropertyProvider>
      </CompanyProvider>
    </UserProvider>
    </GoogleMapsLoader>
  );
}

export default App;

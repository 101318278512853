// src/components/Button.js
import React from 'react';
import PropTypes from 'prop-types';
import './Button.css';

const Button = ({ id, variant, children, onClick, disabled }) => {
  return (
    <button 
      id={id}
      className={`button ${variant}`} 
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  id: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'secondary', 'destructive']),
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  variant: 'primary',
  onClick: () => {},
  disabled: false,
};

export default Button;
// src/utils/ticketUtils.js
import { useTranslation } from 'react-i18next';

const statusTranslationKeys = {
  'OPEN': 'ticketStatus.open',
  'IN PROGRESS': 'ticketStatus.inProgress',
  'CLOSED': 'ticketStatus.closed',
  'ON HOLD': 'ticketStatus.onHold',
};

const priorityTranslationKeys = {
  'Low': 'ticketPriority.low',
  'Medium': 'ticketPriority.medium',
  'High': 'ticketPriority.high',
  'CRITICAL': 'ticketPriority.critical',
};

const priorityFileSrc = {
  'Low': '/images/ticketStatusLow.png',
  'Medium': '/images/ticketStatusMedium.png',
  'High': '/images/ticketStatusHigh.png',
  'CRITICAL': '/images/ticketStatusCritical.png',
};

const priorityColors = {
  'Low': '#00ac46',
  'Medium': '#fdc500',
  'High': '#fd8c00',
  'CRITICAL': '#dc0000',
};

const ticketTypeTranslationKeys = {
  'issue': 'ticketType.issue',
  'project': 'ticketType.project',
  'hoRequest': 'ticketType.hoRequest',
  'internal': 'ticketType.internal',
  'other': 'ticketType.other',
};

const useTicketStatusParser = () => {
  const { t } = useTranslation();

  const parseTicketStatus = (status) => {
    const translationKey = statusTranslationKeys[status] || '';
    return t(translationKey);
  };

  const getPriorityFileSrc = (status) => {
    return priorityFileSrc[status] || '';
  };

  const getPriorityColor = (priority) => {
    return priorityColors[priority] || '#000000'; // Default to black if priority not found
  };

  const parseTicketPriority = (priority) => {
    const translationKey = priorityTranslationKeys[priority] || '';
    return t(translationKey);
  }

  const parseTicketType = (type) => {
    const translationKey = ticketTypeTranslationKeys[type] || '';
    return t(translationKey);
  };

  return { parseTicketStatus, getPriorityFileSrc, getPriorityColor, parseTicketPriority, parseTicketType };
};

export default useTicketStatusParser;
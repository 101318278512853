// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth, signOut } from "firebase/auth";
import { getFunctions } from "firebase/functions";

let firebaseConfig = null;

if (process.env.REACT_APP_ENV === "live") {
  firebaseConfig = {
    apiKey: "AIzaSyAKHYQHqo3c58AA6ZXus2a6H3Rb8Gm4dJY",
    authDomain: "blue-beagle-live.firebaseapp.com",
    databaseURL: "https://blue-beagle-live-default-rtdb.firebaseio.com",
    projectId: "blue-beagle-live",
    storageBucket: "blue-beagle-live.appspot.com",
    messagingSenderId: "946118573334",
    appId: "1:946118573334:web:c064620ffcbc61d68da437",
    measurementId: "G-TVPXCYNLSQ"
  };
} else {
  firebaseConfig = {
    apiKey: "AIzaSyCx8Hrt8Sn-D-BxYGkRGPoyv8PLOf2E10g",
    authDomain: "blue-beagle-development.firebaseapp.com",
    databaseURL: "https://blue-beagle-development-default-rtdb.firebaseio.com",
    projectId: "blue-beagle-development",
    storageBucket: "blue-beagle-development.appspot.com",
    messagingSenderId: "155344600831",
    appId: "1:155344600831:web:5e48512ddcca6db22316ca",
    measurementId: "G-6NSF3D6GP7"
  };
}

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const functions = getFunctions(app);

const signOutUser = async () => {
  await signOut(auth);
  console.log('User signed out');
};

export { auth, functions, signOutUser };

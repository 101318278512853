import React from 'react';
import PropTypes from 'prop-types';
import './RowItem.css';
import { Typography } from '@mui/material';
import { FaChevronRight } from 'react-icons/fa';
import { GoChevronRight } from "react-icons/go";

const RowItem = ({ title, secondaryTitle, details, secondaryDetails, onClick, image, showDetailsIndicator }) => {
  return (
    <div className="row-item" onClick={onClick}>
        {image && (
          <div className="row-item-left">
          <img
            src={image}
            alt=""
            className="row-item-image"
          />
          </div>
        )}
      <div className="row-item-right">
        <div className="row-item-row">
          <Typography variant="subtitle1" className='row-item-title'>{title}</Typography>
          <Typography variant="body2" className='row-item-details'>{details}</Typography>
        </div>
        <div className="row-item-row">
          <Typography variant="body2" className='row-item-secondary-title'>{secondaryTitle}</Typography>
          <Typography variant="body2" className='row-item-secondary-details'>{secondaryDetails}</Typography>
        </div>
      </div>
      {showDetailsIndicator && (
        <div className="row-item-indicator">
          <GoChevronRight />
        </div>
      )}
    </div>
  );
};

RowItem.propTypes = {
  title: PropTypes.string.isRequired,
  secondaryTitle: PropTypes.string.isRequired,
  details: PropTypes.string.isRequired,
  secondaryDetails: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  image: PropTypes.string,
  showDetailsIndicator: PropTypes.bool,
};

export default RowItem;
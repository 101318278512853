
import { functions } from "./firebase";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { httpsCallable } from "firebase/functions";

const callGetUser = async () => {
  const getUser = httpsCallable(functions, 'getUser');
  try {
    const result = await getUser();
    return result.data?.user;
  } catch (error) {
    console.error("Error calling function:", error);
    throw error; 
  }
}

const callGetUserCompanies = async () => {
  const getUserCompanies = httpsCallable(functions, 'getUserCompanies');
  try {
    const result = await getUserCompanies();
    return result.data?.companies;
  } catch (error) {
    console.error("Error calling function:", error);
    throw error;
  }
}

const callGetPlannedSchedules = async(companyId, startTime, endTime) => {
  const getPlannedSchedules = httpsCallable(functions, 'getPlannedSchedules');
  try {
    const result = await getPlannedSchedules({companyId, startTime, endTime});
    return result.data?.plannedSchedules;
  } catch (error) {
    console.error("Error calling function:", error);
    throw error;
  }
}

const callGetAssociates = async(companyId) => {
  const getAssociates = httpsCallable(functions, 'getAssociates');
  try {
    const result = await getAssociates({companyId});
    return result.data?.associates;
  } catch (error) {
    console.error("Error calling function:", error);
    throw error;
  }
}

const callCreateScheduleSummary = async(scheduleSummary) => {
  const createScheduleSummary = httpsCallable(functions, 'createScheduleSummary');
  try {
    const result = await createScheduleSummary({ scheduleSummary });
    return result.data?.scheduleSummary;
  } catch (error) {
    console.error("Error calling function:", error);
    throw error;
  }
}

const callGetTickets = async (companyId, startTime, endTime) => {
  const getTickets = httpsCallable(functions, 'getTickets');
  try {
    const result = await getTickets({companyId, startTime, endTime});
    return result.data?.tickets;
  } catch (error) {
    console.error("Error calling function:", error);
    throw error;
  }
}

const callGetScheduleSummaries = async (companyId, startTime, endTime) => {
  const getScheduleSummaries = httpsCallable(functions, 'getScheduleSummaries');
  try {
    const result = await getScheduleSummaries({companyId, startTime, endTime});
    return result.data?.scheduleSummaries;
  } catch (error) {
    console.error("Error calling function:", error);
    throw error;
  }
}

const callGetTicket = async (ticketId) => {
  const getTicket = httpsCallable(functions, 'getTicket');
  try {
    const result = await getTicket({ticketId});
    return result.data?.ticket;
  } catch (error) {
    console.error("Error calling function:", error);
    throw error;
  }
}

const callCreateTicket = async (ticket, propertyId) => {
  const createTicket = httpsCallable(functions, 'createTicket');
  try {
    const result = await createTicket({ticket, propertyId});
    return result.data?.ticket;
  } catch (error) {
    console.error("Error calling function:", error);
    throw error;
  }
}

const callUpdateTicket = async (ticket) => {
  const updateTicket = httpsCallable(functions, 'updateTicket');
  try {
    const result = await updateTicket({ticket});
    return result.data?.ticket;
  } catch (error) {
    console.error("Error calling function:", error);
    throw error;
  }
}

const callGetProperties = async (companyId) => {
  const getProperties = httpsCallable(functions, 'getProperties');
  try {
    const result = await getProperties({companyId});
    return result.data?.properties;
  } catch (error) {
    console.error("Error calling function:", error);
    throw error;
  }
}

const callDeleteGalleryAsset = async (ticketId, assetId) => {
  const deleteGalleryAsset = httpsCallable(functions, 'deleteGalleryAsset');
  try {
    const result = await deleteGalleryAsset({assetId, ticketId});
    return result.data?.galleryAssets;
  } catch (error) {
    console.error("Error calling function:", error);
    throw error;
  }
}

const callCreateGalleryAsset = async (uid, ticketId, asset) => {
  const storage = getStorage();

  // Convert data URLs to Blob
  const dataURLToBlob = (dataURL) => {
    const byteString = atob(dataURL.split(',')[1]);
    const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const thumbnailBlob = dataURLToBlob(asset.thumbnailUrl);
  const assetBlob = dataURLToBlob(asset.assetUrl);

  const thumbnailRef = ref(storage, `dropbox/${uid}/tickets/${ticketId}/thumbnail.jpg`);
  await uploadBytes(thumbnailRef, thumbnailBlob);

  const assetRef = ref(storage, `dropbox/${uid}/tickets/${ticketId}/asset.jpg`);
  await uploadBytes(assetRef, assetBlob);

  const completeTicketGalleryUpload = httpsCallable(functions, 'completeTicketGalleryUpload');
  try {
    const result = await completeTicketGalleryUpload({ticketId, assetType: 'photo'});
    return result.data?.galleryAssets;
  } catch (error) {
    console.error("Error calling function:", error);
    throw error;
  }
}

const callLogEvents = async (events) => {
  const logEvents = httpsCallable(functions, 'logEvents');
  try {
    await logEvents({events});
  } catch (error) {
    console.error("Error calling function:", error);
    throw error;
  }
}

export { callCreateGalleryAsset, callDeleteGalleryAsset, callGetProperties, callGetUser, callCreateTicket, callGetUserCompanies, callGetPlannedSchedules, callGetAssociates, callCreateScheduleSummary, callGetTickets, callGetScheduleSummaries, callGetTicket, callUpdateTicket, callLogEvents }
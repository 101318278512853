// src/components/GoogleMapsLoader.js
import React from 'react';
import { LoadScript } from '@react-google-maps/api';

const GoogleMapsLoader = ({ children }) => {
  return (
    <LoadScript googleMapsApiKey="AIzaSyAKHYQHqo3c58AA6ZXus2a6H3Rb8Gm4dJY">
      {/* TODO: JD hide the key */}
      {children}
    </LoadScript>
  );
};

export default GoogleMapsLoader;
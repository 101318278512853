// src/components/LoadingIndicator.js
import React from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import './LoadingIndicator.css'; // Import the CSS file

const LoadingIndicator = () => {
  return (
    <div className="loading-indicator">
      <AiOutlineLoading3Quarters />
    </div>
  );
};

export default LoadingIndicator;
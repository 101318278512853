import React from 'react';
import PropTypes from 'prop-types';
import './InfoCard.css';

const InfoCard = ({ title, name, email, address, imageUrl, button }) => {
  return (
    <div className="info-card">
      <div className="info-header">
        <h3>{title}</h3>
        {button && <div className="info-button">{button}</div>}
      </div>
      <div className="info-content">
        {imageUrl && (
          <img src={imageUrl} alt="Profile" className="profile-picture" />
        )}
        <div className="info-details">
          <div className="info-detail-row accent-tint">
            {name}
          </div>
          <div className="info-detail-row primary-color">
            {email}
          </div>
          <div className="info-detail-row secondary-color">
            {address}
          </div>
        </div>
      </div>
    </div>
  );
};

InfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  button: PropTypes.node,
};

export default InfoCard;
// src/components/SegmentedControl.js
import React, { useState, useEffect } from 'react';
import './SegmentedControl.css';

const SegmentedControl = ({ rawValues, displayValues, onTabChange, selectedTab, selectedColor }) => {
  const [currentTab, setCurrentTab] = useState(selectedTab);

  useEffect(() => {
    setCurrentTab(selectedTab);
  }, [selectedTab]);

  const handleTabClick = (rawValue) => {
    setCurrentTab(rawValue);
    onTabChange(rawValue);
  };

  return (
    <div className="segmented-control">
      {rawValues.map((rawValue, index) => (
        <button
          key={rawValue}
          className={`segmented-control-tab ${currentTab === rawValue ? 'active' : ''}`}
          onClick={() => handleTabClick(rawValue)}
          style={currentTab === rawValue ? { backgroundColor: selectedColor } : {}}
        >
          {displayValues[index]}
        </button>
      ))}
    </div>
  );
};

export default SegmentedControl;
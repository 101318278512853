// src/components/NavBar.js
import React from 'react';
import { PiArrowLeft } from 'react-icons/pi';
import Typography from '@mui/material/Typography';
import './NavBar.css';

const NavBar = ({ title, onBackClick, rightButtonTitle, onRightButtonClick }) => {
  return (
    <div className="navbar">
      {onBackClick && (
        <button className="back-button" onClick={onBackClick}>
          <PiArrowLeft size={24} />
        </button>
      )}
      <Typography variant='subtitle1'>{title}</Typography>
      {rightButtonTitle && onRightButtonClick && (
        <button className="right-button" onClick={onRightButtonClick}>
          {rightButtonTitle}
        </button>
      )}
    </div>
  );
};

export default NavBar;
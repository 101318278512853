// src/screens/TicketsScreen.js
import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import NavBar from '../components/NavBar';
import './TicketsScreen.css';
import { callGetTickets } from '../functions';
import LoadingIndicator from '../components/LoadingIndicator';
import RowItem from '../components/RowItem';
import { CompanyContext } from '../context/CompanyContext';
import useTicketStatusParser from '../utils/ticketUtils';
import { Fab } from '@mui/material';
import { FaPlus } from 'react-icons/fa';
import SearchBar from '../components/SearchBar';

const TicketsScreen = () => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(false);
  const { selectedCompany } = useContext(CompanyContext);
  const { parseTicketStatus, getPriorityFileSrc } = useTicketStatusParser();
  const navigate = useNavigate();

  useEffect(() => {
    if (!selectedCompany) return;

    const fetchTickets = async () => {
      setLoading(true);
      try {
        const oneWeekInSeconds = 7 * 24 * 60 * 60;
        const endTime = Math.floor(Date.now() / 1000);
        const startTime = endTime - oneWeekInSeconds;
        const response = await callGetTickets(selectedCompany.id, startTime, endTime);
        setTickets(response);
      } catch {
        alert('Error fetching tickets'); // TODO: JD translation
      } finally {
        setLoading(false);
      }
    };

    fetchTickets();
  }, [selectedCompany]);

  const filteredTickets = tickets.filter(ticket =>
    ticket.title.toLowerCase().includes(searchText.toLowerCase())
  );

  const handleTicketClick = (ticket) => {
    navigate(`/tickets/${ticket.id}`, { state: { ticket } });
  };

  const handleAddTicket = () => {
    navigate('/tickets/new');
  };

  return (
    <div className="tickets-container">
      <NavBar title={t("tickets.title")} className="navbar" />
      <SearchBar
        placeholder={t('scheduleExecution.areaList.search')}
        value={searchText}
        onChange={e => setSearchText(e.target.value)}
      />
      {loading ? (
        <LoadingIndicator />
      ) : (
        <div className="tickets-list">
          {filteredTickets.map(ticket => (
            <RowItem
              key={ticket.id}
              title={ticket.ticketNumber + " - " + ticket.title}
              secondaryTitle={ticket.property.name}
              details={new Date(ticket.date * 1000).toLocaleString()}
              secondaryDetails={parseTicketStatus(ticket.status)}
              image={getPriorityFileSrc(ticket.priority)}
              onClick={() => handleTicketClick(ticket)}
            />
          ))}
        </div>
      )}

      <Fab
        color="primary"
        aria-label="add"
        className="fab"
        onClick={handleAddTicket}
      >
        <FaPlus />
      </Fab>
    </div>
  );
};

export default TicketsScreen;
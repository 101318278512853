// src/geoLocationUtils.js
let cachedGeoLocation = null;
let cachedTimestamp = null;

const fetchGeoLocation = async () => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const geoLocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        cachedGeoLocation = geoLocation;
        cachedTimestamp = Date.now();
        resolve(geoLocation);
      },
      (error) => {
        reject(error);
      },
      { enableHighAccuracy: true }
    );
  });
};

const checkNavigatorPermission = async () => {
  return new Promise((resolve, reject) => {
    navigator.permissions.query({ name: 'geolocation' }).then((result) => {
      resolve(result.state);
    });
  });
};

export const getGeoLocation = async () => {
  const FIVE_MINUTES = 5 * 60 * 1000;
  const now = Date.now();
  const permission = await checkNavigatorPermission();

  switch(permission) {
    case 'granted':
      console.log('Permission granted');
      return await fetchGeoLocation();
    case 'prompt':
      
      if (cachedGeoLocation && cachedTimestamp && (now - cachedTimestamp) < FIVE_MINUTES) {
        console.log('Using cached location');
        return cachedGeoLocation;
      } else {
        console.log('Fetching new location');
        return await fetchGeoLocation();
      }
    case 'denied':
      console.log('Permission denied');
      return cachedGeoLocation;
    default:
      return cachedGeoLocation;
  }
};
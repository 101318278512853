// src/screens/LoginScreen.js
import React, { useEffect, useRef } from 'react';
import { auth } from '../firebase';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebaseui/dist/firebaseui.css';
import './LoginScreen.css'

const firebaseui = require('firebaseui');

const LoginScreen = () => {
  const uiRef = useRef(null);

  useEffect(() => {
    console.log('useEffect called');
    const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth);

    ui.start(uiRef.current, {
      signInFlow: 'popup',
      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      ],
      callbacks: {
        signInSuccessWithAuthResult: () => {
          console.log('Sign-in successful');
          return false;
        },
      },
    });
  }, []);

  return (
    <div className="login-container">
      <img src="images/bluebeagle-black.png" alt="BlueBeagle Logo" className="logo" />
      <div ref={uiRef}></div>
    </div>
  );
};

export default LoginScreen;

// src/components/ScheduleExecutionCheckInPage.js
import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import NavBar from '../components/NavBar';
import { CompanyContext } from '../context/CompanyContext';
import './ScheduleExecutionCheckInPage.css';
import { callGetAssociates } from '../functions';
import { Card, CardContent, Typography, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import Button from '../components/Button';

const ScheduleExecutionCheckInPage = ({ scheduleExecution, setScheduleExecution, handleBack, handleContinue }) => {
  const { t } = useTranslation();
  const { selectedCompany } = useContext(CompanyContext);
  const [counter, setCounter] = useState(scheduleExecution.associatesCount || 0);
  const [associates, setAssociates] = useState(scheduleExecution.availableAssociates || null); // State to store the result of callGetAssociates
  const [selectedAssociatesIds, setSelectedAssociatesIds] = useState(scheduleExecution?.associates?.map(associate=>associate.id) || []); // State to store selected associates

  useEffect(() => {
    scheduleExecution.associatesCount = counter;
    setScheduleExecution({...scheduleExecution});
  }, [counter]);
  
  useEffect(() => {
    scheduleExecution.availableAssociates = associates;
    setScheduleExecution({...scheduleExecution});
  }, [associates]);

  useEffect(() => {
    scheduleExecution.associates = selectedAssociatesIds.map(
      associateId => associates.find(associate => associate.id === associateId)
    );
    setScheduleExecution({...scheduleExecution});
  }, [selectedAssociatesIds]);

  const handleIncrement = async () => {
    const newCounter = counter + 1;
    if(newCounter > 10) {
      return;
    }
    setCounter(newCounter);
    if (newCounter > 0 && associates === null) {
      const result = await callGetAssociates(selectedCompany.id);
      const associatesHeaders = result.map(associate => ({id: associate.id, name: associate.name}));
      console.log("Setting associates", associatesHeaders);
      setAssociates(associatesHeaders);
    }
  };

  const handleDecrement = async () => {
    const newCounter = counter - 1;
    if(newCounter < 0) {
      return;
    }
    setCounter(newCounter);
    setSelectedAssociatesIds(selectedAssociatesIds.slice(0, newCounter));
    if (newCounter > 0 && associates === null) {
      const result = await callGetAssociates(selectedCompany.id);
      const associatesHeaders = result.map(associate => ({id: associate.id, name: associate.name}));
      console.log("Setting associates", associatesHeaders);
      setAssociates(associatesHeaders);
    }
  };

  const handleSelectAssociate = (index, associateId) => {
    const newSelectedAssociatesIds = [...selectedAssociatesIds];
    newSelectedAssociatesIds[index] = associateId;
    setSelectedAssociatesIds(newSelectedAssociatesIds);
  };
  
  const textTitleForAssociate = (count) => {
    if(count === 0) {
      return t('scheduleExecution.checking.noPeople');
    } else {
      return t('scheduleExecution.checking.somePeople') + ' + ' + count;
    }
  }
  
  return (
    <div className="schedule-execution-checkin-page">
      <NavBar title={scheduleExecution.schedule.name} onBackClick={handleBack} />
      <Typography variant="h4" component="div" className="page-title">
        {t('scheduleExecution.checkin.totalNumberOfPeople')}
      </Typography>
      <div className="counter-container">
        <div className="counter-button" onClick={handleDecrement}>-</div>
        <div className="counter-display">{counter}</div>
        <div className="counter-button" onClick={handleIncrement}>+</div>
      </div>
      <Typography variant="h6" component="div" className="count-explanation">
        {textTitleForAssociate(counter)}
      </Typography>
      <Card className="card-margin">
          <CardContent>
            <Typography variant="h6" component="div">
              {selectedCompany.associate.name}
            </Typography>
          </CardContent>
        </Card>
        {Array.from({ length: counter }).map((_, index) => (
        <Card className="card-margin" key={index}>
          <CardContent>
            <FormControl fullWidth>
              <InputLabel>Associates</InputLabel>
              <Select
                value={selectedAssociatesIds[index] || ''}
                onChange={(e) => handleSelectAssociate(index, e.target.value)}
              >
                {associates && associates
                  .filter(associate => !(selectedAssociatesIds.includes(associate.id)) || associate.id === selectedAssociatesIds[index])
                  .map(associate => 
                  <MenuItem value={associate.id}>{associate.name}</MenuItem>
                  )
                }
              </Select>
            </FormControl>
          </CardContent>
        </Card>
      ))}
      <div id="checkin-buttons">
        <Button id="first-button" variant="primary" onClick={handleContinue}>
          {t("scheduleExecution.checkin.continue")}
        </Button>
        <Button variant="secondary" onClick={handleBack}>
        {t("scheduleExecution.checkin.cancel")}
        </Button>
      </div>
    </div>
  );
};

export default ScheduleExecutionCheckInPage;
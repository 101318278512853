import React, { createContext, useState, useEffect, useContext } from 'react';
import { callGetProperties } from '../functions';
import { CompanyContext } from './CompanyContext';

export const PropertyContext = createContext();

export const PropertyProvider = ({ children }) => {
  const [properties, setProperties] = useState([]);
  const { selectedCompany } = useContext(CompanyContext);

  useEffect(() => {
    const fetchProperties = async () => {
      if (selectedCompany) {
        try {
          const propertiesList = await callGetProperties(selectedCompany.id);
          setProperties(propertiesList);
        } catch (error) {
          console.error('Error fetching properties:', error);
        }
      } else {
        setProperties([]);
      }
    };

    fetchProperties();
  }, [selectedCompany]);

  return (
    <PropertyContext.Provider value={{ properties }}>
      {children}
    </PropertyContext.Provider>
  );
};